<!--
******************************************************************
*   1.0.0 - 28/01/2022 - init file - CM
*   1.0.1 - 30/01/2022 - made mobile freindly - CM
*
*
*   file - HeaderImage.vue
*   dependencies - NONE
*
*
******************************************************************-->
<template>
    <div class="header-outer">
        <div class="header-image">
            <img src="@/assets/images/419-1080.webp" alt="">
        </div>
        <div class="colour-overlay"></div>
        <div class="header-text">
            <h1>{{title}}</h1>
        </div>
    </div>
</template>

<style lang="scss" scoped>

.header-outer{
    height: 250px;
    position: relative;
    @media (max-width:768px) //1.0.1
    {
        height: 200px;
    }
    @media (max-width:425px) //1.0.1
    {
        height: 140px;
    }
}
.header-image{
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
    top: 0;
    left: 0;

    @media (max-width:768px) //1.0.1
    {
        height: 200px;
    }
    @media (max-width:425px) //1.0.1
    {
        height: 140px;
    }
    img{
        position: absolute;
        left: 0;
        margin-top: -20%;
    }
}
.header-text{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    h1{
        margin: none;
        color: #FFFFFF;
    }
}
.colour-overlay{
    overflow: hidden;
    height: 250px;
    @media (max-width:768px) //1.0.1
    {
        height: 200px;
    }
    @media (max-width:425px) //1.0.1
    {
        height: 140px;
    }
}
</style>

<script>
export default {
    name: 'HeaderImage',
    props: {
        title: {type: String}
    }
}
</script>