<!--
******************************************************************
*   1.0.0 - 08/01/2022 - init file - CM
*   1.0.1 - 28/01/2022 - added about us with headerimage - CM
*   1.0.2 - 30/01/2022 - changed span to multi spans - CM
*   1.0.3 - 30/01/2022 - Added caravan club and chnaged h2 colour - CM
*
*
*   file - Home.vue
*   dependencies - NONE
*
*
******************************************************************-->
<template>
  <div class="about">
    <!-- <h1>This is an about page</h1> -->
    <HeaderImage title="About Us"/>
    <div class="text-area">
      <h2>Welcome</h2>
      <span>Welcome to Fairview Tourist Park, please allow us the opportunity to thank you for your enquiry. </span> <!-- 1.0.2 -->
      <span>We are situated on the outskirts of Bude as part of the secluded village of Poughill, which is exactly 1.5 miles from Bude town centre.
        Bude town holds a substantive amount of amenities including 2 large super markets, various gift and clothes shops & restaurants catering for all tastes and ages.Situated on the outskirts of Bude are Splash Baths & Harlequins Bowling Alley which also includes "Curves" Fitness Suit.</span>
      <span>This historic town remains one of Cornwalls most beautiful and untouched sights, which boasts some of the most breath taking and panoramic costal sea views in the UK.</span>
      <h2>Site</h2>
      <span>Caravan & Campervans are welcome, dogs are also welcome but they must be kept on a lead and under control at all time.</span>
      <span>Electric hook-ups are available, laundrette & ice packs are also available.</span>
      <span>Clean modern tiled toilets with basins and situated in the middle of the site and are easily accessible. Attached to the ablutions is a fully working utility room with a washing machine 
        & tumble dryer. These are operted using old style 50 pence pieces which can be acquired from Mr. Inch, all hot water is free. Gas bottles are also available on site.
      </span>
      <h2>Tents</h2>
      <span>Tents may be pitched with prior arrangement, please contact us before arriving on site.
      </span>
      <h2>Caravan and Motorhome Club</h2> <!-- 1.0.3 -->
      <span>We currently only accept bookings for Caravan and Motorhome Club members. For more information please visit <a href="https://www.caravanclub.co.uk/membership/">Here</a></span>
    </div>
  </div>
</template>
<script>
import HeaderImage from '@/components/HeaderImage.vue'
export default {
  name: 'About',
  components: {
    HeaderImage
  }
}
</script>
<style lang="scss" scoped>
  .text-area{
    // border: solid black 1px;
    margin: 5px 10%;
    text-align: left;  
    h2{ //1.0.3
      color: #59791a;
    }
  }
  span{
    display: inline-block;
    margin-bottom: 10px;
    a{ //1.0.3
      color: #59791a;
    }
  }
</style>