<!--
******************************************************************
*   1.0.0 - 08/01/2022 - init file - CM
*   1.0.1 - 28/01/2022 - added headerimage - CM
*   1.0.2 - 30/01/2022 - added text and stlying - CM
*
*
*   file - Book.vue
*   dependencies - NONE
*
*
******************************************************************-->
<template>
  <div class="booking">
    <!-- <h1>This is the booking page</h1> -->
    <HeaderImage title="Book with us"/> <!--1.0.1-->
    <div class="text-area"> <!--1.0.2 -->
      <h2>Booking information</h2>
      <span>Thank you for inquiring into booking a plot with us!</span>
      <span>Currently we only accept bookings via phone calls</span>
      <span>To inquire about booking times and more information regarding our park, please email us at 
        <a href="mailto:inquires@fairviewtouristpark.co.uk?subject=Booking inquiry">inquires@fairviewtouristpark.co.uk</a>
      </span>
      <h2>Booking a pitch</h2>
      <span>To book a pitch please phone us at <a href="tel:01288 353018">01288 353018</a></span>
    </div>
  </div>
</template>
<script>
import HeaderImage from '@/components/HeaderImage.vue'
export default {
  name: 'Book',
  components: {
    HeaderImage
  }
}
</script>
<style lang="scss" scoped> //1.0.2
  .text-area{
    // border: solid black 1px;
    margin: 5px 10%;
    text-align: left;  
    h2{ //1.0.2
      color: #59791a;
    }
  }
  span{
    display: inherit;
    margin-bottom: 10px;
    a{ //1.0.2
      color: #59791a;
    }
  }
</style>
