<!--
******************************************************************
*   1.0.0 - 08/01/2022 - init file - CM
*   1.0.1 - 28/01/2022 - added headerimage - CM
*
*
*   file - Gallery.vue
*   dependencies - NONE
*
*
******************************************************************-->
<template>
  <div class="gallery">
    <HeaderImage title="Gallery"/> <!--1.0.1-->
  </div>
</template>
<script>
import HeaderImage from '@/components/HeaderImage.vue'
export default {
  name: 'Gallery',
  components: {
    HeaderImage
  }
}
</script>